import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { ThreeDots } from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import moment from 'moment';
import { useTable, usePagination, useSortBy } from 'react-table';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter
} from "@material-tailwind/react";
import { InlineIcon } from '@iconify/react';


export default function FoodEntryTable() {
    const { promiseInProgress } = usePromiseTracker();
    const [foodItemData, setFoodItemData] = useState([]);
    const { user } = UserAuth();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);
    const [entryToDelete, setEntryToDelete] = useState([]);
    const [indexToDelete, setIndexToDelete] = useState([]);
    const [foodItemToDelete, setFoodItemToDelete] = useState([]);
    const [rawMessageToDelete, setRawMessageToDelete] = useState([]);
    const [dateLoggedOnToDelete, setDateLoggedOnToDelete] = useState([]);

    useEffect(() => {
        if (user.accessToken) {
            const token = user.getIdToken()
            const fetchRawData = async () => {

                const axios_config = {
                    method: 'GET',
                    url: process.env.REACT_APP_API_URL + `/api/v1/food-items-table`,
                    headers: {
                        "Authorization": `Bearer ${user.accessToken}`
                    },
                    params: {
                        timezoneOffset: new Date().getTimezoneOffset(),
                    },
                  };

                const response = await trackPromise(axios(axios_config))

                const data = await response.data;

                console.log("Food Items Table:", data)

                fetchFoodItemData(data);

            }
            // Fetch 'feed' data then aggregate it
            fetchRawData();
        }
    }, [user]);

    const fetchFoodItemData = (data) => {

        if (data.length > 0) {
            var result = [];
            var foodItemList = [];

            data.map(function (item) {
                item.food_items.map(function (food_item, index) {
                    var f = {}
                    f['index'] = food_item.food_index;
                    f['entryId'] = item.doc_id;
                    f['dtDateAdded'] = item.logged_at;
                    f['dtMealDate'] = item.meal_date;
                    f['foodLabel'] = food_item.food_label;
                    f['foodItem'] = food_item.food_item;
                    f['mealType'] = item.meal_type
                    f['portionSize'] = food_item.portion_size
                    f['pointsActual'] = food_item.points
                    f['pointsTarget'] = item.points_target
                    f['rawMessage'] = item.raw_message
                    f['tzOffsetMins'] = item.timezone_offset_mins
                    foodItemList.push(f)
                })


            })
            console.log("Food Item List: ", foodItemList)
            setFoodItemData(foodItemList.sort((a, b) => (a.entryId < b.entryId) ? 1 : -1))
        }
    }

    function handleEdit(row) {
        handleOpen();
        console.log(row);
        setEntryToDelete(row.entryId);
        setIndexToDelete(row.index);
        setFoodItemToDelete(row.foodItemOriginal)
        setRawMessageToDelete(row.rawMessage)
        setDateLoggedOnToDelete(moment(row.dtAddedLocal).format('ddd DD MMM HH:mm'))
    };

    const handleDeleteFoodItem = async () => {
        handleOpen();
        const response = await trackPromise(axios.get(process.env.REACT_APP_API_URL + `/api/v1/delete-entry-food-item`, { params: { entryId: entryToDelete, itemId: indexToDelete }, headers: { "Authorization": `Bearer ${user.accessToken}` } }))

        window.location.reload(false);
    }

    const handleDeleteMeal = async () => {
        handleOpen();
        const response = await trackPromise(axios.get(process.env.REACT_APP_API_URL + `/api/v1/delete-entry-meal`, { params: { entryId: entryToDelete }, headers: { "Authorization": `Bearer ${user.accessToken}` } }))

        window.location.reload(false);
    }

    const LoadingIndicator = props => {
        return (
            promiseInProgress &&
            <div
                style={{
                    width: "100%",
                    height: "100",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
            </div>
        );
    };


    const FoodItemTable = () => {
        const columns = useMemo(() => [
            {
                Header: 'Meal Date',
                accessor: row => moment.unix(row.dtMealDate).subtract(row.tzOffsetMins, 'minutes').format('DD MMM YYYY')
            },
            {
                Header: 'Date Added',
                accessor: row => moment.unix(row.dtDateAdded).subtract(row.tzOffsetMins, 'minutes').format('DD MMM YY HH:mm')
            },
            {
                Header: 'Meal Type',
                accessor: row => row.mealType
            },
            {
                Header: 'Food Category',
                accessor: row => row.foodLabel
            },
            {
                Header: 'Food',
                accessor: row => row.foodItem
            },
            {
                Header: 'Portion Size',
                accessor: row => row.portionSize
            },
            {
                Header: 'Points',
                accessor: row => row.pointsActual
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: row => (
                    <div>
                        <button style={{ textDecoration: 'underline' }} onClick={e => handleEdit(row.row.original)}>Delete</button>
                    </div>
                ),
            }
        ], []);

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            //rows,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
            //prepareRow
        } = useTable(
            {
                columns,
                data: foodItemData,
                initialState: { pageSize: 5 }
            }, useSortBy, usePagination);

        return (

            (promiseInProgress === true) ? <LoadingIndicator /> :
                <div className="grid grid-cols-1 px-4 mb-16">
                    <Card>
                        <CardHeader className="text-left p-4 bg-pinky">
                            <Typography variant="h4" className='text-light'>
                                History
                            </Typography>
                        </CardHeader>
                        <CardBody>
                            <div className="overflow-x-auto">
                                <table {...getTableProps()} style={{ textTransform: 'capitalize' }} className="items-center w-full bg-transparent border-collapse">
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    // Add the sorting props to control sorting. For this example
                                                    // we can add them into the header props
                                                    <th className="px-2 text-pinky align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left" {...column.getHeaderProps(column.getSortByToggleProps())}>

                                                        {column.render('Header')}
                                                        {/* Add a sort direction indicator */}
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? <InlineIcon icon="material-symbols:keyboard-arrow-down-rounded" width="16" color="purple" />
                                                                : <InlineIcon icon="material-symbols:keyboard-arrow-up-rounded" width="16" color="purple" />
                                                            : ''}

                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </CardBody>
                        <CardFooter>
                            <div className="flex flex-1 justify-between">
                                <Button className='hover:opacity-80 text-pinky bg-light border border-pinky' onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
                                <p className="text-sm text-gray-700 ">
                                    <span className='mx-2'>
                                        Page{' '}
                                        {pageIndex + 1} of {pageOptions.length}
                                    </span>
                                    <span className='mx-2'>
                                        <select
                                            value={pageSize}
                                            onChange={e => {
                                                setPageSize(Number(e.target.value))
                                            }}
                                        >
                                            {[5, 10, 20, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Show {pageSize}
                                                </option>
                                            ))}
                                        </select>
                                    </span>

                                </p>
                                <Button className='hover:opacity-80 text-pinky bg-light border border-pinky' onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
                            </div>
                        </CardFooter>
                    </Card>
                </div>
        );
    };

    return (
        <div>

            <FoodItemTable />

            <Dialog open={open} handler={handleOpen} size={"xxl"}>
                <DialogHeader>Confirm Delete</DialogHeader>
                <DialogBody divider>

                    Are you sure you want to delete:
                    {<br />}
                    <p style={{ fontWeight: 'bold', lineHeight: 2 }}>
                        Food item:<span> </span>
                        <span style={{ fontWeight: 'normal' }}>
                            {foodItemToDelete}
                        </span>{<br />}
                        From this message:{<br />}
                        <span style={{ fontWeight: 'normal' }}>
                            "{rawMessageToDelete}"
                        </span>{<br />}
                        Logged on: <span style={{ fontWeight: 'normal' }}>
                            {dateLoggedOnToDelete}
                        </span>{<br />}
                        {<br />}
                        Or you can delete this entire meal. Just select the option below.
                    </p>


                </DialogBody>
                <DialogFooter>

                    <Button
                        className='mr-2 my-2 w-full lg:w-2/12 border border-primary bg-light text-primary' onClick={handleDeleteFoodItem}>
                        <span>Delete Specific Item</span>
                    </Button>

                    <Button className='mr-2 my-2 w-full lg:w-2/12 bg-primary text-light' onClick={handleDeleteMeal}>
                        <span>Delete Entire Meal</span>
                    </Button>
                    <Button
                        onClick={handleOpen}
                        className="mr-2 my-2 w-full lg:w-2/12 bg-light text-primary"
                    >
                        <span>Cancel</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    )
}